<template>
  <div class="electrical">
    <div class="left">
      <div class="left-top">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name" id="test">设备在线率</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r1-c1-content-wrap">
                <div class="r1-c1-content-row1">
                  <div class="r1-c1-content-row1-item">
                    设备总数
                    <div class="num">{{ onlineData.total }}台</div>
                  </div>
                  <div class="r1-c1-content-row1-item">
                    使用数量
                    <div class="num">{{ onlineData.online }}台</div>
                  </div>
                  <div class="r1-c1-content-row1-item">
                    停用数量
                    <div class="num">{{ onlineData.offline }}台</div>
                  </div>
                </div>
                <div class="r1-c1-content-row2">设备在线率</div>
                <div class="r1-c1-content-row3">
                  <div class="max">
                    <div
                      class="val"
                      :style="{
                        width:
                          (onlineData.online / onlineData.total).toFixed(2) * 100 + '%',
                      }"
                    ></div>
                  </div>
                  <div class="percent">
                    {{ (onlineData.online / onlineData.total).toFixed(2) * 100 }}%
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="left-bottom">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">种类报警信息</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div id="echarts-c1-r2"></div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <span>今日用电量: {{ realData.currDayEpiValue }} Kw</span>
        <span>本月用电量: {{ realData.currMonthEpiValue }} Kw</span>
      </div>
      <div class="bottom">
        <div
          class="item"
          v-for="(item, index) in realData.powerMonitorVoList"
          :key="index"
        >
          <div class="border-wrap">
            <div class="border-top flex">
              <div class="border-top-left">
                <img src="../assets/border/border-left-top.png" />
              </div>
              <div class="border-top grow">
                <img src="../assets/border/border-top.png" />
              </div>
              <div class="border-title">
                <div class="border-name">{{ item.deviceName }}</div>
              </div>
              <div class="border-top grow">
                <img src="../assets/border/border-top.png" />
              </div>
              <div class="border-top-right">
                <img src="../assets/border/border-right-top.png" />
              </div>
            </div>
            <div class="border-body row-height-2 flex">
              <div class="border-left">
                <img src="../assets/border/border-left.png" />
              </div>
              <!--  -->
              <div class="border-body-middle grow">
                <div class="item-wrap">
                  <div class="item-row">
                    <div class="item-row-span">
                      <div class="item-row-span-name">A相<br />电压</div>
                      <div class="item-row-span-value">
                        {{ item.phaseVolta }}V
                      </div>
                    </div>
                    <div class="item-row-span">
                      <div class="item-row-span-name">A相<br />电流</div>
                      <div class="item-row-span-value">{{ item.eleca }}A</div>
                    </div>
                  </div>
                  <div class="item-row">
                    <div class="item-row-span">
                      <div class="item-row-span-name">B相<br />电压</div>
                      <div class="item-row-span-value">
                        {{ item.phaseVoltb }}V
                      </div>
                    </div>
                    <div class="item-row-span">
                      <div class="item-row-span-name">B相<br />电流</div>
                      <div class="item-row-span-value">{{ item.elecb }}A</div>
                    </div>
                  </div>
                  <div class="item-row">
                    <div class="item-row-span">
                      <div class="item-row-span-name">C相<br />电压</div>
                      <div class="item-row-span-value">
                        {{ item.phaseVoltc }}V
                      </div>
                    </div>
                    <div class="item-row-span">
                      <div class="item-row-span-name">C相<br />电流</div>
                      <div class="item-row-span-value">{{ item.elecc }}A</div>
                    </div>
                  </div>
                  <div class="item-row">
                    <div class="item-row-span">
                      <div class="item-row-span-name">平均<br />电压</div>
                      <div class="item-row-span-value">
                        {{ item.phaseVoltavg }}V
                      </div>
                    </div>
                    <div class="item-row-span">
                      <div class="item-row-span-name">平均<br />电流</div>
                      <div class="item-row-span-value">{{ item.elecAvg }}A</div>
                    </div>
                  </div>
                  <div class="item-pie" :id="`pie${index}`"></div>
                </div>
              </div>
              <!--  -->
              <div class="border-right">
                <img src="../assets/border/border-right.png" />
              </div>
            </div>
            <div class="border-bottom flex">
              <div class="border-bottom-left">
                <img src="../assets/border/border-left-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-middle">
                <img src="../assets/border/border-middle-bottom.png" />
              </div>
              <div class="border-bottom grow">
                <img src="../assets/border/border-bottom.png" />
              </div>
              <div class="border-bottom-right">
                <img src="../assets/border/border-right-bottom.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@Width: 192rem;
.row-height-1 {
  height: 335 / @Width;
}
.row-height-2 {
  height: 685 / @Width;
}
.electrical {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .left {
    width: 660 / @Width;
  }
  .right {
    width: 1160 / @Width;
    .top {
      height: 60 / @Width;
      background: linear-gradient(
        rgba(26, 62, 184, 0.8),
        rgba(17, 40, 117, 0.8)
      );
      border: 1px solid #1736c2;
      box-sizing: border-box;
      text-align: center;

      span {
        font-size: 24 / @Width;
        color: #00e4ff;
        margin: 0 60 / @Width;
        line-height: 58 / @Width;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      .item {
        width: 570 / @Width;
      }
    }
  }
  .left,
  .right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.border-wrap {
  .flex {
    display: flex;
  }
  .grow {
    flex-grow: 1;
  }
  .border-top {
    .border-top-left,
    .border-top-right {
      width: 50 / @Width;
      height: 50 / @Width;
      img {
        width: 50 / @Width;
        height: 50 / @Width;
      }
    }
    .border-top {
      height: 50 / @Width;
      img {
        width: 100%;
        height: 50 / @Width;
      }
    }
    .border-title {
      width: 205 / @Width;
      height: 50 / @Width;
      background-image: url("../assets/border/border-title.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      .border-name {
        color: #00e4ff;
        font-size: 18 / @Width;
        font-weight: 700;
        text-align: center;
        margin-top: 8 / @Width;
      }
    }
  }

  .border-body {
    .border-left,
    .border-right {
      width: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 100%;
      }
    }
    .border-body-middle {
      background-image: url("../assets/border/border-body-bg.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
    }
  }

  .border-bottom {
    .border-bottom-left,
    .border-bottom-right {
      width: 25 / @Width;
      height: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 25 / @Width;
      }
    }
    .border-bottom {
      height: 25 / @Width;
      img {
        width: 100%;
        height: 25 / @Width;
      }
    }
    .border-bottom-middle {
      width: 258 / @Width;
      height: 25 / @Width;
      img {
        width: 258 / @Width;
        height: 25 / @Width;
      }
    }
  }
}

.r1-c1-content-wrap {
  // background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .r1-c1-content-row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .r1-c1-content-row1-item {
      width: 174 / @Width;
      height: 151 / @Width;
      background-image: url("../assets/device/device-online.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 22 / @Width;
      .num {
        color: #ffbe22;
        font-size: 26 / @Width;
        font-weight: 700;
      }
    }
  }
  .r1-c1-content-row2 {
    font-size: 24 / @Width;
    color: #ffbe22;
    margin-top: 20 / @Width;
    margin-bottom: 20 / @Width;
  }
  .r1-c1-content-row3 {
    height: 40 / @Width;
    display: flex;
    .max {
      flex-grow: 1;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 20 / @Width;
      overflow: hidden;
      .val {
        height: 40 / @Width;
        background-color: #ffbe22;
        border-radius: 20 / @Width;
      }
    }
    .percent {
      margin-left: 10 / @Width;
      font-size: 24 / @Width;
      line-height: 40 / @Width;
      color: #ffbe22;
    }
  }
}

#echarts-c1-r2 {
  width: 100%;
  height: 100%;
}

.item-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .item-row {
    display: flex;
    justify-content: center;
    margin: 10 / @Width 0;
    .item-row-span {
      width: 230 / @Width;
      height: 100 / @Width;
      margin: 0 15 / @Width;
      background-image: url("../assets/person/r1c1-item-bg.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      display: flex;
      .item-row-span-name {
        text-align: center;
        width: 88 / @Width;
        height: 100 / @Width;
        font-size: 20 / @Width;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .item-row-span-value {
        width: 142 / @Width;
        height: 100 / @Width;
        font-size: 26 / @Width;
        font-weight: 700;
        text-align: center;
        line-height: 100 / @Width;
        color: #ffbe22;
      }
    }
  }
  .item-pie {
    width: 100%;
    flex-grow: 1;
  }
}
</style>


<script>
export default {
  name: "Electrical",
  components: {},
  data() {
    return {
      onlineData: { total: 0, online: 0, offline: 0 },
      arlarmData: [],
      realData: {
        currDayEpiValue: 0,
        currMonthEpiValue: 0,
        powerMonitorVoList: [],
      },
      echarts: {
        c1r2: null,
      },
    };
  },
  computed: {
    projectList: function () {
      return this.$store.state.projectList;
    },
    currentProject: function () {
      return this.$store.state.currentProject;
    },
    itemPie: function () {
      var obj = {};
      this.realData.powerMonitorVoList.forEach((item, index) => {
        obj["pie" + index] = null;
      });
      return obj;
    },
  },
  watch: {
    // 监听项目切换
    currentProject(newVal, oldVal) {
      this.onGetOnlineData(); // 获取设备在线率
      this.onGetArlarmData(); // 获取种类报警信息
      this.onGetRealData(); // 获取实时数据
    },
  },
  mounted() {
    this.onGetOnlineData(); // 获取设备在线率
    this.onGetArlarmData(); // 获取种类报警信息
    this.onGetRealData(); // 获取实时数据
  },
  methods: {
    // 获取设备在线率
    onGetOnlineData: function () {
      var that = this;
      var data = {
        seType: 5,
        projectId: this.currentProject.id,
      };
      if (!this.currentProject.id) return;
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularStatisticsByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.onlineData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取种类报警信息
    onGetArlarmData: function () {
      var that = this;
      var data = {
        seType: 5,
        projectId: this.currentProject.id,
      };
      if (!this.currentProject.id) return;
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/powerMonitor/powerMonitorArlarmByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.arlarmData = res.data.itemList;
            that.echartsC1R2Init(); // 绘制种类报警信息饼图
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取实时数据
    onGetRealData: function () {
      var that = this;
      var data = {
        seType: 5,
        projectId: this.currentProject.id,
      };
      if (!this.currentProject.id) return;
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/powerMonitor/powerMonitorRealListByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.realData = res.data;
            that.itemPieInit(); // 循环渲染饼图
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 绘制种类报警信息饼图
    echartsC1R2Init: function () {
      this.echarts.c1r2 = this.$echarts
        .init(document.getElementById("echarts-c1-r2"))
        .dispose();
      this.echarts.c1r2 = this.$echarts.init(
        document.getElementById("echarts-c1-r2")
      );
      var listData = this.arlarmData;
      var data = [];
      var legend = [];
      listData.forEach((item, index) => {
        data.push({
          name: item.alarmName,
          value: item.alarmNum,
        });
        legend.push(item.alarmName);
      });
      console.log("data=>", data);
      console.log("legend=>", legend);
      var option = {
        legend: {
          data: legend,
          orient: "vertical",
          bottom: 10,
          right: 10,
          textStyle: {
            color: "#00e4ff",
          },
          itemWidth: 7,
          itemHeight: 7,
          formatter: function (name) {
            var arr;
            listData.forEach((item, index) => {
              if (name == item.alarmName) {
                arr =
                  item.alarmName + item.alarmNum + "次 " + item.alarmPer + "%";
              }
            });
            return arr;
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        series: [
          {
            name: "种类报警信息",
            type: "pie",
            center: ["35%", "50%"],
            roseType: "radius",
            itemStyle: {
              borderRadius: 5,
            },
            label: {
              formatter: "{d}%",
              color: "#00e4ff",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: data,
          },
        ],
      };
      this.echarts.c1r2.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.c1r2.resize();
      });
    },
    // 循环渲染饼图
    itemPieInit: function () {
      var timer = setTimeout(() => {
        this.realData.powerMonitorVoList.forEach((item, index) => {
          this.itemPie["pie" + index] = this.$echarts
            .init(document.getElementById("pie" + index))
            .dispose();
          this.itemPie["pie" + index] = this.$echarts.init(
            document.getElementById("pie" + index)
          );
          var option = {
            series: [
              {
                name: item.deviceName,
                type: "pie",
                radius: ["40%", "70%"],
                data: [
                  { value: 0, name: item.temp1 + ' ℃' },
                  { value: 0, name: item.temp2 + ' ℃' },
                  { value: 0, name: item.temp3 + ' ℃' },
                  { value: 0, name: item.temp4 + ' ℃' },
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              label: {
                color: "#00e4ff",
              },
              },
            ],
          };
          this.itemPie["pie" + index].setOption(option);
          window.addEventListener("resize", () => {
            this.itemPie["pie" + index].resize();
          });
        });
        clearTimeout(timer);
      }, 500);
    },
  },
};
</script>